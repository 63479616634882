<template>
  <TableWithPagination :tableData="targetLists"
    ref="orderTable"
    :columnList="columnList"
    :pagination.sync="pagination"
    :sortInfo.sync="updateSortInfo"
    @current-change="getTargetLists"
    @sort-change="getTargetLists"
  ></TableWithPagination>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
export default {
  name: 'CertificateType',
  mixins: [InfoMixin],
  methods: {
    initPage () {
      this.getTargetLists();
    },
    getTargetLists () {
      // let currentParams = this.getCurrentParams();
      // SystemSetting.getAccountLists(currentParams).then(this.getTargetListsSuccessFun);
    },
    handleDetail () {
      let settings = Object.assign({}, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('是否要设置该企业为默认', '', settings).then(() => {
        // 这里调用接口
      });
    },
  },
  data () {
    return {
      columnList: [
        {
          tooltip: true,
          label: '证件名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '证件号码',
          minWidth: '150px',
          prop: 'number',
        },
        {
          tooltip: true,
          label: '发证日期/授权日期',
          prop: 'dateOfIssue',
          minWidth: '150px',
          format: (val) => this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '到期日期/授权到期日期',
          minWidth: '150px',
          prop: 'validUntil',
          format: (val) => this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '附件',
          minWidth: '150px',
          prop: 'attachmentUrl',
          render: (h, {row}) => {
            if (row.attachmentUrl) {
              return <el-image preview-src-list={[row.attachmentUrl]} referrer-policy style={'width:40px;height:40px;vertical-align: middle;margin-right:10px'} src={row.attachmentUrl} ></el-image>;
            }
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
