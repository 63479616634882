<template>
  <div class='JZContainer'>
    <content-block  title='我的企业'>
      <!-- <el-descriptions :column="2">
        <el-descriptions-item label="企业名称">{{companyInfo.name||'--'}}</el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">{{companyInfo.unifiedSocialCreditCode||'--'}}</el-descriptions-item>
        <el-descriptions-item label="法定代表人"> {{companyInfo.legalPersonName||'--'}}</el-descriptions-item>
        <el-descriptions-item label="登记状态">
          {{companyInfo.registerStatus||'--'}}
        </el-descriptions-item>
        <el-descriptions-item label="成立日期">{{companyInfo.establishmentDate||'--'}}</el-descriptions-item>
        <el-descriptions-item label="核准日期">{{companyInfo.approvedDate||'--'}}</el-descriptions-item>
        <el-descriptions-item label="注册资本">{{companyInfo.registeredCapital||'--'}}</el-descriptions-item>
        <el-descriptions-item label="实缴资本">{{companyInfo.paidInCapital||'--'}}</el-descriptions-item>
        <el-descriptions-item label="企业类型">{{companyInfo.enterpriseType||'--'}}</el-descriptions-item>
        <el-descriptions-item label="营业有效期至">{{companyInfo.businessValidUntilDate||'--'}}</el-descriptions-item>
        <el-descriptions-item label="所属行业">{{companyInfo.belongIndustry||'--'}}</el-descriptions-item>
        <el-descriptions-item label="所属地区">{{companyInfo.belongDistrictProvinceName||'--'}}</el-descriptions-item>
        <el-descriptions-item label="人员规模">{{companyInfo.staffSize||'--'}}</el-descriptions-item>
        <el-descriptions-item label="参保人数">{{companyInfo.participantNum||'--'}}</el-descriptions-item>
        <el-descriptions-item label="企业英文名称">{{companyInfo.nameEn||'--'}}</el-descriptions-item>
        <el-descriptions-item label="进出口企业代码">{{companyInfo.importExportEnterpriseCode||'--'}}</el-descriptions-item>
        <el-descriptions-item label="注册地址" :span="2">{{companyInfo.registeredAddress||'--'}}</el-descriptions-item>
        <el-descriptions-item label="经营范围" :span="2">{{companyInfo.businessScope||'--'}}</el-descriptions-item>
      </el-descriptions> -->
      <el-form label-width="150px" label-position="right">
        <el-row>
          <el-col :span='12'>
            <el-form-item label='企业名称：'>
              {{companyInfo.name||'--'}}
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='统一社会信用代码：'>
              {{companyInfo.unifiedSocialCreditCode||'--'}}
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='法定代表人：'> {{companyInfo.legalPersonName||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='登记状态：'>{{companyInfo.registerStatus||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='成立日期：'>{{companyInfo.establishmentDate||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='核准日期：'>{{companyInfo.approvedDate||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='注册资本：'>{{companyInfo.registeredCapital||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='实缴资本：'>{{companyInfo.paidInCapital||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='企业类型：'>{{companyInfo.enterpriseType||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='营业有效期至：'>{{companyInfo.businessValidUntilDate||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='所属行业：'>{{companyInfo.belongIndustry||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='所属地区：'>{{companyInfo.belongDistrictProvinceName||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='人员规模：'>{{companyInfo.staffSize||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='参保人数：'>{{companyInfo.participantNum||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='企业英文名称：'>{{companyInfo.nameEn||'--'}}</el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='进出口企业代码：'>{{companyInfo.importExportEnterpriseCode||'--'}}</el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label='注册地址：'>{{companyInfo.registeredAddress||'--'}}</el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label='经营范围：'>{{companyInfo.businessScope||'--'}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </content-block>
    <content-block>
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card" size='small'>
        <el-tab-pane label="企业联系人" name="1">
          <EnterpriseContact ref="enterpriseContact"></EnterpriseContact>
        </el-tab-pane>
        <el-tab-pane label="收货信息" name="2">
          <ReceivingInformation ref="receivingInformation"></ReceivingInformation>
        </el-tab-pane>
        <el-tab-pane label="仓库信息" name="3">
          <WarehouseInformation ref="warehouseInformation"></WarehouseInformation>
        </el-tab-pane>
        <el-tab-pane label="银行资料" name="4">
          <BankInfo ref="bankInfo"></BankInfo>
        </el-tab-pane>
        <!-- <el-tab-pane label="经营类目" name="5">
          <BusinessCategory ref="businessCategory"></BusinessCategory>
        </el-tab-pane> -->
        <el-tab-pane label="证件信息" name="6">
          <CertificateType ref="certificateType"></CertificateType>
        </el-tab-pane>
      </el-tabs>
    </content-block>
    <BottomPanel>
      <el-button type="default" @click="$router.go(-1)">返回</el-button>
    </BottomPanel>
  </div>
</template>

<script>
import EnterpriseContact from './components/enterpriseContact.vue';
import ReceivingInformation from './components/receivingInformation.vue';
import WarehouseInformation from './components/warehouseInformation.vue';
import BankInfo from './components/bankInfo.vue';
// import BusinessCategory from './components/businessCategory.vue';
import CertificateType from './components/certificateType.vue';
import ContentBlock from '../../components/common/layout/contentBlock.vue';
import {detailSupplier} from '@/api/userCenter';
import {dateFormat} from '@/utils/dateFormat';
export default {
  name: 'DetailCompany',
  components: {
    EnterpriseContact,
    ReceivingInformation,
    WarehouseInformation,
    BankInfo,
    // BusinessCategory,
    CertificateType,
    ContentBlock,
  },
  methods: {
    getDeatailInfo () {
      let currentParams = {
        id: this.$route.query.id,
        findBankFlag: true,
        findContactPersonFlag: true,
        findCredentialFlag: true,
        findReceiverFlag: true,
        findWarehouseFlag: true,
      };
      detailSupplier(currentParams).then(res => {
        let currentInfo = res.body;
        currentInfo.establishmentDate = currentInfo.establishmentDate ? dateFormat(currentInfo.establishmentDate).format('YYYY-MM-DD') : '';
        currentInfo.approvedDate = currentInfo.approvedDate ? dateFormat(currentInfo.approvedDate).format('YYYY-MM-DD') : '';
        currentInfo.businessValidUntilDate = currentInfo.businessValidUntilDate ? dateFormat(currentInfo.businessValidUntilDate).format('YYYY-MM-DD') : '';
        let distributorNatures = currentInfo.distributorNatures || [];
        let manufacturingEnterpriseNatures = currentInfo.manufacturingEnterpriseNatures || [];
        let currentLists = distributorNatures.concat(manufacturingEnterpriseNatures);
        let companyPeroperty = currentLists.map(item => this.companyProperty[item]).join('、');
        currentInfo['companyPeroperty'] = companyPeroperty;
        let cooperationAgreementTypeDes = this.agreementTypeProperty[currentInfo.cooperationAgreementType];
        currentInfo['cooperationAgreementTypeDes'] = cooperationAgreementTypeDes;
        let registerStatusDes = this.registerStatusProperty[currentInfo.registerStatus] || currentInfo.registerStatus;
        currentInfo['registerStatus'] = registerStatusDes;
        currentInfo['enterpriseType'] = this.econKindOptionsProperty[currentInfo.enterpriseType] || currentInfo.enterpriseType;
        this.companyInfo = currentInfo;
        this.$refs.enterpriseContact.targetLists = currentInfo.contactPersonList;
        this.$refs.receivingInformation.targetLists = currentInfo.receiverGoodsList;
        this.$refs.warehouseInformation.targetLists = currentInfo.warehouseList;
        this.$refs.bankInfo.targetLists = currentInfo.bankList;
        this.$refs.certificateType.targetLists = currentInfo.credentialList.filter(item => item.number || item.attachmentUrl);
        // this.$refs.bankInfo.businessCategory = currentInfo.bankList;
      });
    },
    handleClick () {
      let status = this.activeName;
      if (status === '1') {
        this.$refs.enterpriseContact.initPage();
      } else if (status === '2') {
        this.$refs.receivingInformation.initPage();
      } else if (status === '3') {
        this.$refs.warehouseInformation.initPage();
      } else if (status === '4') {
        this.$refs.bankInfo.initPage();
      } else if (status === '5') {
        this.$refs.businessCategory.initPage();
      } else if (status === '6') {
        this.$refs.certificateType.initPage();
      }
    },
  },
  created () {
    this.getDeatailInfo();
  },
  data () {
    return {
      activeName: '1',
      companyInfo: {},
      companyProperty: {
        'NON_MEDICAL_DEVICE_MANUFACTURER': '非医疗器械生产企业',
        'MEDICAL_DEVICE_MANUFACTURER_CLASS_I': '医疗器械生产企业（一类）',
        'MEDICAL_DEVICE_MANUFACTURER_CLASS_II': '医疗器械生产企业（二类）',
        'MEDICAL_DEVICE_MANUFACTURER_CLASS_III': '医疗器械生产企业（三类）',
        'NON_MEDICAL_DEVICE_DISTRIBUTOR': '非医疗器械经销商',
        'MEDICAL_DEVICE_OPERATING_COMPANIES_CLASS_I': '医疗器械经营企业（二类）',
        'MEDICAL_DEVICE_OPERATING_COMPANIES_CLASS_II': '医疗器械生产企业（二类）',
        'MEDICAL_DEVICE_OPERATING_COMPANIES_CLASS_III': '医疗器械经营企业（三类）',
      },
      agreementTypeProperty: {
        'STANDARD': '标准供应商合作协议',
        'NON_STANDARD': '供应商非标准合作协议',
      },
      registerStatusProperty: {
        'SUBSISTING': '存续',
        'IN_CAMP': '在营',
        'RESIDENT': '在册',
        'OPENED': '开业',
      },
      econKindOptionsProperty: {
        '10': '有限责任公司',
        '20': '股份有限公司',
        '30': '国企',
        '40': '外商投资企业',
        '50': '个人独立企业',
        '60': '合伙制企业',
        '70': '个体工商户',
        '80': '联营企业',
        '90': '集体所有制',
        '100': '有限合伙',
        '110': '普通合伙',
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
