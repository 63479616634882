<template>
  <TableWithPagination :tableData="targetLists"
    ref="orderTable"
    :columnList="columnList"
    :pagination.sync="pagination"
    :sortInfo.sync="updateSortInfo"
    @current-change="getTargetLists"
    @sort-change="getTargetLists"
  ></TableWithPagination>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
export default {
  name: 'BankInfo',
  mixins: [InfoMixin],
  methods: {
    initPage () {
      this.getTargetLists();
    },
    getTargetLists () {
      // let currentParams = this.getCurrentParams();
      // SystemSetting.getAccountLists(currentParams).then(this.getTargetListsSuccessFun);
    },
    handleDetail () {
      let settings = Object.assign({}, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('是否要设置该企业为默认', '', settings).then(() => {
        // 这里调用接口
      });
    },
  },
  data () {
    return {
      columnList: [
        {
          tooltip: true,
          label: '银行名称',
          minWidth: '150px',
          prop: 'bankName',
        },
        {
          tooltip: true,
          label: '开户行',
          minWidth: '150px',
          prop: 'bankOfDeposit',
        },
        {
          tooltip: true,
          label: '银行账号',
          minWidth: '150px',
          prop: 'bankAccount',
        },
        {
          tooltip: true,
          label: '是否默认',
          minWidth: '100px',
          prop: 'defaultFlag',
          format: (val) => {
            return val === false ? '否' : val === true ? '是' : '--';
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
